import uniqueIdGenerator from '@/utils/generateUniqueId'

export const checkNotification = async (lang) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}requirement-manager/notify-recent-lead`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('authToken'),
        locale: lang,
        Cookie: `i18next=${lang}`,
        'x-request-id': uniqueIdGenerator.getId()
      },
    })

    const res = await response.json()
   
    if (res?.status) {
      if (res?.data !== null) {
        return res?.data
      } else {
        return null
      }
    } else {
      throw res.status
    }
  } catch (error) {
    return error
  }
}
