import dynamic from 'next/dynamic'
import {
  getPreviousPath,
  isUserLoggedIn,
  pushDataLayerEventHandler,
  getScrollDepth,
  mixpanelTrackPageview,
  mixpanelSetIndentity,
  updateAuthKeys
} from '@/utils/utils'
import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isDesktop, isMobileOnly } from 'react-device-detect'
import { toast } from 'react-toastify'
import { getParamsFromQuery } from 'services/queryString'
import { checkNotification } from 'services/checkNotification'
import UCookies from 'universal-cookie'
import css from './layout.module.scss'
import { header_exempted_pages } from '@/utils/constant'
import LayoutHead from './LayoutHead'
import { analytics } from '@/components/GTM/gtm'
import { initGA } from '@/utils/analytics'
import uniqueIdGenerator from '@/utils/generateUniqueId'
import mixpanel from 'mixpanel-browser';
import headerFooterEn from './headerFooterEn.json'
import headerFooterAr from './headerFooterAr.json'

const ErrorBoundary = dynamic(()=>import('../ErrorBoundary')) 
const OpenAppHeader = dynamic(()=>import('../OpenAppHeader'))
const Header =dynamic(()=>import('@/components/Common/SEOHeader/Header')) 
const Footer =dynamic(()=>import('@/components/Common/Footer/Footer')) 
const IconoGraphy = dynamic(() => import('../IconoGraphy'))

let handleExpiredPropertyAlertCloseFunction = null
let redirectToPropertyFunction = null
let redirectToRequestCardFunction = null

let clientMethods = null
let importMethods = null

const Layout = (props) => {
  const { state ,dispatch,tabsOpen} = useAppContext()
  const { commonReducer ,searchReducer } = state
  
  const { isOpenAppHeaderEnabled, isMapBoxLoaded } = commonReducer
  const {
    isSuggestedUnit = false,
    metaData,
    children,
    title,
    keywords,
    description,
    headerCities,
    footerLinks,
    currentPage,
    propertyDetails,
    imageUrl,
    wrapperClass,
    customClass,
    isThankyouPopup,
    slugQueries = {},
    showpopup = false,
    pageType,
    showOpenAppHeader = true,
    hideHeaderFooter = false,
    udpCompoundId = null,
    firstImageURL
  } = props
  const { t, lang } = useTranslation('translations')
  const router = useRouter()
  const { query } = router
  const headerFooter = lang === 'en' ? headerFooterEn : headerFooterAr
  const routerParams = getParamsFromQuery({ ...query, ...slugQueries })
  const [stickyHeader, setStickyHeader] = useState(false)
  const [isOpenAppHeader, setIsOpenAppHeader] = useState(false)
  const [isCookieAlertClosed, setIsCookieAlertClosed] = useState(false)
  const [showCookiePopUp, setShowCookiePopUp] = useState(false)
  const [onLoadItems, setOnLoadItems] = useState({
    isExpiredPropertyAlertClosed: undefined,
  })
  const [scrollDir, setScrollDir] = useState('scrolling down')
  const [toggleNotificaiton, setToggleNotificaiton] = useState({ open: false, propertyCount: 0 })
  const [isMobile,setIsMobile] = useState(false)
  const [CookieAlert, setCookieAlert] = useState(false)
  const notificationId = 'broker-acceptance-notification' // notification id for closing received notifications after SYR broker acceptance

  let displayStyle = ''
  let customDisplayStyle = `${css.openAppHeader} ${css.isNotDesktop}`

  let queryOriginMobile = null
  if (router.query.origin === 'mobileapp') {
    queryOriginMobile = true
  } else {
    queryOriginMobile = false
  }
  /**
   * -This variable for hiding section from mobile view
   */
  const importClientMethods = async () => {
    if (!clientMethods) {
      clientMethods = await import(/*webpackChunkName : 'clientMethods'*/'../SEOHeader/Methods/onClickMethods')
    }
  }

  const dynamicImportMethods = async()=>
  {
    if(!importMethods){
      importMethods = await import(/*webpackChunkName : 'importMethods'*/'../SEOHeader/Methods/importMethods')
    }
  }

  const loadDynamicContent = async () => {
    await dynamicImportMethods()
    if(!queryOriginMobile &&
      !isCookieAlertClosed  &&
      !showCookiePopUp &&
      pageType != 'Subuser-Invitation') importMethods.importCookieAlert({setCookieAlert})
  }


  let querySourceAppView = null
  const cmpRedirctUrl = router.query.redirectUrl

  if (router.query.source === 'appWebView') {
    querySourceAppView = true
  } else if (cmpRedirctUrl && cmpRedirctUrl.includes('appWebView')) {
    querySourceAppView = true
  } else if (typeof window !== 'undefined' && window.localStorage.getItem('source') == 'appWebView') {
    querySourceAppView = true
  } else {
    querySourceAppView = false
  }

  let propertyCategory = ''
  if (routerParams.countryId) {
    if (routerParams.countryId === 1) {
      propertyCategory = 'domestic'
    } else {
      propertyCategory = 'international'
    }
  } else if (propertyDetails?.propertyRegionId !== 57) {
    propertyCategory = 'domestic'
  } else {
    propertyCategory = 'international'
  }

  const handleScroll = async () => {
    await importClientMethods()
    clientMethods.handleScroll({ scrollY, setStickyHeader })
  }

  useEffect (() => {
    setIsMobile(isMobileOnly)
  }, [isMobileOnly])
  /**
   *Cookies managment with auction and post property
   */

  /**
   * screenName changes if propertyDetails is present or not
   * @param {Object} propertyDetails
   * @returns {String} screenName
   */
  
  useEffect(() => {
    if (isDesktop || querySourceAppView) {
      setIsOpenAppHeader(true)
    }
    setIsCookieAlertClosed(window.sessionStorage.getItem('isCookieAlertClosed') != null)
    setShowCookiePopUp(window.localStorage.getItem('cookieAccept') != null)

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [typeof window != 'undefined' && window.sessionStorage])

  useEffect(() => {
    loadDynamicContent()
    if (window.localStorage.getItem('authToken') && !isExpiredPropertyAlertClosed) {
      fetchExpiredPropertiesCount()
    }
    window.dataLayer = window.dataLayer || []
    const cookies = new UCookies()
    updateAuthKeys(cookies, window)
    mixpanelSetIndentity() // moved code to common function in utils
    window.addEventListener('scroll', handleScroll)
    setOnLoadItems({
      ...onLoadItems,
      isExpiredPropertyAlertClosed: localStorage.getItem('isExpiredPropertyAlertClosed'),
    })
   
    if (pageType !== 'SRP List') {
      let device
      if (isMobile) {
        device = 'mobile'
      } else if (!isMobile) {
        device = 'desktop'
      } else {
        device = 'tablet'
      }
      let property_main_type = "";
        if (propertyDetails) { 
          property_main_type = propertyDetails.propIdentityTypeId === 96 ? "project" : "property"; 
        }
        let landing_page = "";
        if(sessionStorage.getItem('landing_page') === 'true'){
          landing_page = false
        }
        else{
          landing_page = true
          sessionStorage.setItem('landing_page','true')
        }
      const dataDetails = {
        page_type: pageType,
        service: routerParams?.propertyFor
          ? routerParams?.propertyFor
          : propertyDetails?.propertyInfo?.propertyFor || '',
        property_category: propertyCategory,
        login_status: isUserLoggedIn(),
        user_mobile: window?.localStorage.getItem('encryptedPhoneNumber') || "",
        device_category: device,
        property_main_type,
        city_id: routerParams.cityId ? routerParams.cityId : propertyDetails?.propertyInfo.cityId ?? "",
        district_id: routerParams.districtId ? routerParams.districtId : propertyDetails?.propertyInfo.districtId ?? "",
        property_type: routerParams.propertyTypeData ? routerParams.propertyTypeData.join(',') : propertyDetails?.propertyInfo.propertySubTypeSlug ?? "",
        property_sub_category: routerParams.type ? routerParams.type : propertyDetails?.propertyInfo.propertyMainTypeSlug ?? "",
        landing_page
      }
      mixpanelTrackPageview(dataDetails) // moved code to common function in utils
      if (process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' || process.env.NEXT_PUBLIC_IS_PREPROD == 'true') {
        if (landing_page) {
          mixpanel.track('landing_page', dataDetails)
        }
      }
  
    }


    const previousUrl = localStorage.getItem('previousUrl') || '';
    const currentUrl = window.location.href;
    if (currentUrl === previousUrl) {
      localStorage.setItem('marketing_channel_grouping', 'Session Refresh')
    }
    else{
      localStorage.removeItem('marketing_channel_grouping')
    }
    localStorage.setItem('previousUrl', currentUrl);

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const mapViewBtnClicked = JSON.parse(localStorage.getItem('m_mapViewBtnClicked'))
    if (isMobileOnly && !JSON.parse(window.sessionStorage.getItem('isOpenHeaderClosed'))) {
      setIsOpenAppHeader(mapViewBtnClicked || false)
    }
  }, [isOpenAppHeaderEnabled])

  useEffect(() => {
    if (typeof window === 'undefined') return
    const handleRouteChange = (url) => {
      const fromAbsherData = router.query.data
      if (!fromAbsherData) {
        let property_main_type = "";
        if (propertyDetails) { 
          property_main_type = propertyDetails.propIdentityTypeId === 96 ? "project" : "property"; 
        }
        let page_hit_type = ''
        if (routerParams.districtId) {
          page_hit_type = 'district'
        } else if (routerParams.cityId) {
          page_hit_type = 'city'
        }
        const details = {
          page: decodeURI(url),
          page_type: pageType ?? '',
          service: routerParams?.propertyFor
            ? routerParams?.propertyFor
            : propertyDetails?.propertyInfo?.propertyFor || '',
          property_category: propertyCategory,
          login_status: window.localStorage.getItem('authToken') ? true : false,
          user_mobile: window?.localStorage.getItem('encryptedPhoneNumber') || "",
          city_id: routerParams.cityId ? routerParams.cityId : propertyDetails?.propertyInfo.cityId ?? "",
          district_id: routerParams.districtId ? routerParams.districtId : propertyDetails?.propertyInfo.districtId ?? "",
          property_type: routerParams.propertyTypeData ? routerParams.propertyTypeData.join(',') : propertyDetails?.propertyInfo.propertySubTypeSlug ?? "",
          property_main_type,
          property_sub_category: routerParams.type ? routerParams.type : propertyDetails?.propertyInfo.propertyMainTypeSlug ?? "",
          page_hit_type,
          previous_screen: decodeURI(getPreviousPath()),
        }
        analytics(details)
      }
    }

    if (!window.GA_INITIALIZED) {
      window.GA_INITIALIZED = true
      if (router?.locale == 'en') {
        handleRouteChange(`/${router.locale}${router.asPath}`)
      } else {
        handleRouteChange(router.asPath)
      }
    } else {
      if (router?.locale == 'en') {
        handleRouteChange(`/${router.locale}${router.asPath}`)
      } else {
        handleRouteChange(router.asPath)
      }
    }
  }, [router.events, router.query])

  // based upon user loggedin status and tabs count notification will be shown
  useEffect(() => {
    const renderNotification = async () => {
      if (isUserLoggedIn() && tabsOpen == 1) {
        const notificationData = await checkNotification(lang)
        if (notificationData?.requirementId) {
          const eventData = {
            notification_type: 'web',
            platform: isDesktop ? 'website' : 'msite',
            request_id: notificationData?.requirementId,
            broker_id: notificationData?.brokerId,
            user_id: localStorage.getItem('userId'),
            timestamp: Date.now(),
          }
          showNotification(notificationData?.requirementId, notificationData?.brokerId)
          pushDataLayerEventHandler(window, lang, 'match_notification_received', pageType, eventData)
        }
      }
    }
    renderNotification()
  }, [tabsOpen])

  const IS_PRODUCTION = process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' ? true : false

  const EXPIRE_PROPERTY_ALERT_PATHS = ['', 'myproperty']
  const isExpiredPropertyAlertClosed =
    typeof window !== 'undefined' && window.localStorage.getItem('isExpiredPropertyAlertClosed')
  const path = router?.pathname?.split('/').pop()

  const fetchExpiredPropertiesCount = async () => {
   try {
    const res = await fetch(
      `/api/properties/crud?apiUrl=${process.env.NEXT_PUBLIC_GATEWAY_URL}post-property/get-expired-properties-count`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          locale: lang,
          authorization: window.localStorage.getItem('authToken'),
          'x-request-id': uniqueIdGenerator.getId()
        },
      },
    )
      if(!res.ok){
        throw new Error(`Failed to fetch expired properties, HTTP error Status:${res.status}`)
      }
    const response = await res.json()
    if (response?.data?.status) {
      if (response?.data?.data?.propertyExpiryCount > 0) {
        setToggleNotificaiton({ open: true, propertyCount: response?.data?.data?.propertyExpiryCount })
        import('@/utils/observer').then(({ observerService }) => {
          observerService.handleEvent({ type: 'showExpiredPropertyAlert', data: { visibility: true } })
        })
      }
    }
   } catch (error) {
      console.error("Error occurred:", error)
   }
  }

  const handleExpiredPropertyAlertClose = async () => {
    if (handleExpiredPropertyAlertCloseFunction === null) {
      const { handleExpiredPropertyAlertCloseMethod } = await import(
        /* webpackChunkName: "handleExpiredPropertyAlertClose" */ '../SEOHeader/Methods/onClickMethods'
      )
      handleExpiredPropertyAlertCloseFunction = handleExpiredPropertyAlertCloseMethod
    }
    handleExpiredPropertyAlertCloseFunction(setToggleNotificaiton)
  }

  const redirectToProperty = async () => {
    if (redirectToPropertyFunction === null) {
      const { redirectToPropertyMethod } = await import(
        /* webpackChunkName: "redirectToProperty" */ '../SEOHeader/Methods/onClickMethods'
      )
      redirectToPropertyFunction = redirectToPropertyMethod
    }
    redirectToPropertyFunction(handleExpiredPropertyAlertClose, router, lang)
  }

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.scrollY
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.scrollY

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      if (window.scrollY > 300) {
        setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      const h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight'

      const sVal = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)
      const percent = Math.round(sVal * 100)
      if (currentPage && currentPage === 'home' && isMobileOnly) {
        if (!searchReducer?.isHomeMsiteOpen) {
          dispatch({ type: 'SET_SCROLL_DEPTH', payload: getScrollDepth(percent) })
        }
        if (!ticking) {
          if (!searchReducer?.isHomeMsiteOpen) {
            window.requestAnimationFrame(updateScrollDir)
          }
          ticking = true
        }
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  /**
   * @param {*} requirementId latest requirement id which gets received from backend
   * @param {*} brokerId latest broker id which gets received from backend
   */
  const showNotification = async (requirementId, brokerId) => {
    await importClientMethods()
    clientMethods.showNotification(
      {requirementId, brokerId, t,
      redirectToRequestCard, lang, notificationId, toast, isDesktop, pageType}
    )
  }

  const redirectToRequestCard = async (requirementId, brokerId) => {
    if (redirectToRequestCardFunction === null) {
      const { redirectToRequestCardMethod } = await import(
        /* webpackChunkName: "redirectToRequestCard" */ '../SEOHeader/Methods/onClickMethods'
      )
      redirectToRequestCardFunction = redirectToRequestCardMethod
    }
    redirectToRequestCardFunction(requirementId, brokerId, isDesktop, router, lang, pageType)
  }

  /**
   * @param {*} requirementId latest requirement id which gets received from backend
   * @param {*} brokerId latest broker id which gets received from backend
   */

  let alertMsg 
  if(toggleNotificaiton?.propertyCount === 1){
    alertMsg = t('MYProperty.SINGLE_PROPERTY_EXPIRE_ALERT')
  }
  else{
    alertMsg = t('MYProperty.MULTIPLE_PROPERTIES_EXPIRE_ALERT')
  }

  let displayMsg
  if (toggleNotificaiton?.propertyCount > 0 && path === '') {
    displayMsg = t('MYProperty.GO_TO_MY_PROPERTIES')
  } else if (toggleNotificaiton?.propertyCount === 1 && path === 'myproperty') {
    displayMsg = t('MYProperty.SHOW_PROPERTY')
  } else if (toggleNotificaiton?.propertyCount > 1 && path === 'myproperty') {
    displayMsg = t('MYProperty.SHOW_PROPERTIES')
  } else {
    displayMsg = null
  }
  
  if (isOpenAppHeader ||
    !showOpenAppHeader ||
    (isMapBoxLoaded && 
     (pageType !== 'PDP' && 
      pageType !== 'CDP' && 
      pageType !== 'UDP' && 
      pageType !== 'SRP'))) {
    displayStyle = css.display_none;
}

if (queryOriginMobile &&
  querySourceAppView &&
  hideHeaderFooter &&
  isOpenAppHeader &&
  showpopup &&
  header_exempted_pages.includes(pageType)) {
  customDisplayStyle = css.display_none; 
}
  return (
    <>
      {/* Layout Head  */}
      <LayoutHead
        currentPage={currentPage}
        metaData={metaData || null}
        title={title || null}
        t={t}
        keywords={keywords || null}
        description={description || null}
        firstImageURL={firstImageURL || null}
        isThankyouPopup={isThankyouPopup || null}
        IS_PRODUCTION={IS_PRODUCTION}
        asPathURL={router.asPath}
        currentLang={lang}
      />

      {toggleNotificaiton?.open &&
      EXPIRE_PROPERTY_ALERT_PATHS.includes(path) &&
      !onLoadItems.isExpiredPropertyAlertClosed ? (
        <>
          <div className={css.infoAlert}>
            <IconoGraphy iconClass={'icon-clock'} />
            <span className={css.infoAlert_container}>
              {alertMsg}
              <a className={css.infoAlert_link} onClick={redirectToProperty}>
                {displayMsg}
              </a>
            </span>
            <span className={css.infoAlert_close} onClick={handleExpiredPropertyAlertClose}>
              <IconoGraphy iconClass={'icon-close'} />
            </span>
          </div>
        </>
      ) : (
        // Layout Header Seaction
        <>
          {(!searchReducer?.isHomeStickyInputClicked || pageType !== 'Home') && (
            <div
            className={`${displayStyle} 
      ${querySourceAppView && css.display_none}
      ${customDisplayStyle}`}
            >
              <OpenAppHeader
                handleCloseOpenApp={() => {
                  setIsOpenAppHeader(true)
                }}
                pageType={pageType}
              />
            </div>
          )}
        </>
      )}

      {!querySourceAppView && !hideHeaderFooter && !queryOriginMobile && !header_exempted_pages.includes(pageType) && (
        <Header
          isSuggestedUnit={isSuggestedUnit}
          headerCities={headerCities === undefined || 
            (typeof headerCities === 'object' && Object.keys(headerCities).length === 0) ? headerFooter?.navigationLinks : headerCities}
          page={currentPage}
          locale={props.locale}
          stickyHeader={stickyHeader}
          metaData={metaData || null}
          propertyDetails={propertyDetails}
          isOpenAppHeader={isOpenAppHeader}
          customClass={customClass}
          slugQueries={slugQueries}
          pageType={pageType}
          udpCompoundId={udpCompoundId}
          showTopClass={
            toggleNotificaiton?.open && EXPIRE_PROPERTY_ALERT_PATHS.includes(path) && !isExpiredPropertyAlertClosed
          }
        />
      )}

      {/* Layout Main Seaction  */}
      <ErrorBoundary>{children}</ErrorBoundary>
      {/* Layout Footer Seaction */}

      {footerLinks
        ? !querySourceAppView &&
          !hideHeaderFooter &&
          !queryOriginMobile && (!isMobile || (isMobile && !isMapBoxLoaded)) && (
            <Footer
              footerLinks={footerLinks === undefined || 
                (typeof footerLinks === 'object' && Object.keys(footerLinks).length === 0) ? headerFooter?.footerLinks : footerLinks}
              page={currentPage}
              locale={props.locale}
              metaData={metaData || null}
              propertyDetails={propertyDetails}
              wrapperClass={wrapperClass}
              pageType={pageType}
              udpCompoundId={udpCompoundId}
            />
          )
        : null}

      {CookieAlert && !queryOriginMobile &&
      !isCookieAlertClosed &&
      !showCookiePopUp &&
      pageType != 'Subuser-Invitation' ? (
        <CookieAlert.default/>
      ) : null}
    </>
  )
}

export default Layout
