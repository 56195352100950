import Head from 'next/head'
const LayoutHead = ({
  currentPage,
  metaData,
  title,
  t,
  keywords,
  description,
  firstImageURL,
  isThankyouPopup,
  IS_PRODUCTION,
  asPathURL,
  currentLang
}
) =>(
      <Head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
        <meta name="format-detection" content="telephone=no"></meta>
        <title>
          {currentPage == 'home' ? t('Home.HOME_HEADING') : metaData?.meta_title || title || t('Home.SEARCH_HEADING')}
        </title>
        <meta name='keyword' content={keywords} />
        <meta name='apple-itunes-app' content='' />
        {/* For share images and content to social media */}
        <meta property='og:site_name' content='Wasalt'></meta>
        <meta
          property='og:title'
          content={
            currentPage == 'home' ? t('Home.HOME_HEADING') : metaData?.meta_title || title || t('Home.SEARCH_HEADING')
          }
        />
        <meta
          property='og:description'
          content={currentPage == 'home' ? t('Home.HOME_DESCRIPTION') : description || t('AboutUS.ABOUT_TITLE')}
        />
        <meta property='og:image' content={firstImageURL} />
        <meta property='og:rich_attachment' content='true' />
        <meta
          name='description'
          content={currentPage == 'home' ? t('Home.HOME_DESCRIPTION') : description || t('AboutUS.ABOUT_TITLE')}
        />
        <meta 
        itemProp='name' 
        content={
            currentPage == 'home' ? t('Home.HOME_HEADING') : metaData?.meta_title || title || t('Home.SEARCH_HEADING')
          }
        />
        <meta 
        itemProp='description' 
        content={currentPage == 'home' ? t('Home.HOME_DESCRIPTION') : description || t('AboutUS.ABOUT_TITLE')}
        />
        <meta 
        itemProp='url' 
        content={`${process.env.NEXT_PUBLIC_BASE_URL}${currentLang == 'en' ? '/en' : ''}${asPathURL}`}
        />
        <meta 
        itemProp='image' 
        content={firstImageURL}
        />
        <link rel='preconnect' href='https://connect.facebook.net' />
        <link rel='dns-prefetch' href='https://connect.facebook.net'/>
        <link rel='preconnect' href='https://www.googletagmanager.com' />
        <link rel='dns-prefetch' href='https://www.googletagmanager.com'/>
        <link rel='preconnect' href='https://www.google-analytics.com' />
        <link rel='dns-prefetch' href='https://www.google-analytics.com'/>
        <link rel='preconnect' href='https://www.googletagservices.com' />
        <link rel='dns-prefetch' href='https://www.googletagservices.com'/>
        <link rel='preconnect' href='https://www.clarity.ms' />
        <link rel='dns-prefetch' href='https://www.clarity.ms'/>
        <link rel='preconnect' href={`${process.env.NEXT_PUBLIC_BASE_URL}`} />
        <link rel='dns-prefetch' href={`${process.env.NEXT_PUBLIC_BASE_URL}`}/>

        {/* <meta name='robots' content='max-image-preview:large'/>
        <meta name='robots' content='index, follow'/> */}
        <meta name='msapplication-tilecolor' content='#253d97' />
        <meta name='msapplication-tileimage' content='/favicon/ms-icon-144x144.png' />
        {/* Facebook domain verification metatag added */}
        <meta name='facebook-domain-verification' content='n1vb44qqvjikpcrzw2hg70lzy6ukwq' />
        {process.env.NEXT_PUBLIC_ADD_NO_INDEX == 'true' && <meta name='robots' content='noindex'></meta>}
        <link rel='preconnect' href={process.env.NEXT_PUBLIC_CDN_BASE_URL} />
        <link rel='preload' href='https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oTd4g.woff2' as='font' crossorigin='anonymous'/>
        <link rel='preload' href='https://cdn.wasalt.sa/fonts_fe/CSRk4ydQnPyaDxEXLFF6LZVLKrodrOYFFg.woff2' as='font' crossorigin='anonymous'/>
        <link rel='shortcut icon' href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/ms-icon-144x144.png`} />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/apple-touch-icon-180.png`}
        ></link>
        <link
          rel='apple-touch-icon'
          sizes='120x120'
          href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/apple-touch-icon-120.png`}
        ></link>
        <link
          rel='apple-touch-icon'
          sizes='167x167'
          href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/apple-touch-icon-167.png`}
        ></link>
        <link
          rel='apple-touch-icon'
          sizes='152×152'
          href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/apple-touch-icon-152.png`}
        ></link>
        <link rel='preconnect' href={process.env.NEXT_PUBLIC_BASE_FILE_URL} />
        {process.env.NEXT_PUBLIC_IS_PRODUCTION == 'true' && (
          <link rel='preconnect' href={process.env.NEXT_PUBLIC_CDN_BASE_FILE_URL} />
        )}
        {isThankyouPopup && IS_PRODUCTION && (
          <noscript>
            <p style='margin:0;padding:0;border:0;'>
              <img
                src='https://track.adform.net/Serving/TrackPoint/?pm=2160985&ADFPageName=Dar%20Al%20Arkan&ADFdivider=|'
                width='1'
                height='1'
                alt=''
              />
            </p>
          </noscript>
        )}
        {isThankyouPopup && IS_PRODUCTION && (
          <noscript>
            <img
              src='https://ad.doubleclick.net/ddm/activity/src=10561830;type=invmedia;cat=daral0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?'
              width='1'
              height='1'
              alt=''
            />
          </noscript>
        )}
      </Head>
)
export default LayoutHead