// Google Analytics Tracking Script

import Script from "next/script";

export const initGA = () => (
      <Script
        id='google-tag-manager'
        strategy='afterInteractive'
        onLoad={() => {
          // Initialize Google Analytics
          window.ga = window.ga || function () {
            (window.ga.q = window.ga.q || []).push(arguments);
          };
          window.ga.l = +new Date();
          window.ga('create', `${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`, 'auto');
          window.ga('send', 'pageview');
        }}
        src='https://www.google-analytics.com/analytics.js'
      />
  );

export const logPageView = (data) => {
  if (typeof window === 'undefined') return;
  window.ga('send', 'pageview', data.page);
};

export const logEvent = (category = '', action = '') => {
  if (typeof window === 'undefined' || !category || !action) return;

  window.ga('send', 'event', category, action);
};

export const logException = (description = '', fatal = false) => {
  if (typeof window === 'undefined' || !description) return;

  window.ga('send', 'exception', {
    exDescription: description,
    exFatal: fatal,
  });
};
